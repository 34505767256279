.side_nav {
    position: fixed;
    top: 30vh;
    width: 52px;
    background: var(--primary-color);
    backdrop-filter: blur(4px);
    border: 1px solid var(--primary-color);
    border-left: none;
    z-index: 10;
    transition: width 0.5s ease;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.side_nav ul {
    list-style: none;
    padding: 0 1rem;
    margin: 0;
}

.side_nav ul li .side_icon {
    font-size: 20px;
}

.side_nav ul li .side_text {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    line-height: 26px;
}

.side_nav ul li a {
    display: flex;
    align-items: end;
    gap: 5px;
    font-size: 20px;
    margin: 10px 0;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    color: black;
}

.side_nav ul li a:hover {
    color: white;
}

.side_nav:hover ul li .side_text {
    display: block;
}

.side_nav:hover {
    width: 190px;
}

.side_nav:hover ul li .side_text {
    opacity: 1;
    visibility: visible;
}