.footer_section {
    color: #fff;
}

.footer_section .footer_logo {
    height: 4rem;
}

.footer_section .upper_footer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
        'logo links1 links2 social';
    gap: 0 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 30px;
}

.logo {
    grid-area: logo;
}

.links1 {
    grid-area: links1;
}

.links2 {
    grid-area: links2;
}

.social {
    grid-area: social;
}

.footer_section .footer_links {
    display: flex;
    flex-direction: column;
}

.footer_section .upper_footer a {
    font-size: 20px;
    text-decoration: none;
    color: white;
    transition: 0.3s all;
}

.footer_section .upper_footer a.footer_text:hover {
    cursor: pointer;
    color: var(--primary-color);
}

.social>p {
    margin-bottom: 0;
}

.social_icons_container {
    display: flex;
    gap: 4px;
}

.social_icons_container .social_icon {
    cursor: pointer;
    transition: 0.3s;
    font-size: 16px;
    padding: 6px;
    border-radius: 20px;
    border: 2px solid #fff;
    color: #fff;
    display: flex;
    align-items: center;
}

.social_icons_container .instagram:hover {
    background-color: #e6683c;
    color: #fff;
}

.social_icons_container .facebook:hover {
    background: #1877f2;
    color: #fff;
}

.social_icons_container .twitter:hover {
    background: #1da1f2;
    color: #fff;
}

.social_icons_container .linkedin:hover {
    background: #0274b3;
    color: #fff;
}

.social_icons_container .youtube:hover {
    background: #cd242b;
    color: #fff;
}

.lower_footer {
    display: flex;
    justify-content: space-between;
    padding: 0px 30px;
}

.lower_footer p {
    margin: 0;
    color: rgba(255, 255, 255, 0.7)
}

.lower_footer .links {
    text-decoration: underline;
    cursor: pointer;
    color: #fff;
}

@media (max-width: 991px) {
    .footer_section .footer_logo {
        height: 3rem;
    }

    .footer_section .upper_footer a {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .footer_section .upper_footer {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            'logo links1'
            'social links2';
        gap: 20px 20px;
    }

}