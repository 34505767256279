.introSection {
    overflow: hidden;
    margin-top: 4rem;
}


.mainSection {
    display: flex;
    gap: 10px;
    align-items: center;

}

.mainSection .mainSectionLeft {
    width: 60%;
}

.textSlideContainer {
    height: 100px;
}

.textSlideContainer div h1 {
    display: inline-block;
    color: white;
    font-size: 72px;
    font-weight: 600;
    margin-bottom: 0;
}

.textSlideContainer .firstText {
    animation: fadingAnimation 15s ease infinite;
}

.textSlideContainer .secondText {
    position: relative;
    top: -85px;
    animation: fadingAnimation2 15s linear infinite;
}

@keyframes fadingAnimation {

    0%,
    40% {
        opacity: 1;
    }

    45%,
    75% {
        opacity: 0;
    }

    80%,
    100% {
        opacity: 1;
    }
}

@keyframes fadingAnimation2 {

    0%,
    45% {
        opacity: 0;
    }

    50%,
    70% {
        opacity: 1;
    }

    75%,
    100% {
        opacity: 0;
    }
}

@keyframes animate {
    0% {
        margin-top: 0px;
    }

    25% {
        margin-top: 0px;
    }

    35% {
        margin-top: -100px;
    }

    65% {
        margin-top: -100px;
    }

    75% {
        margin-top: 0px;
    }

    100% {
        margin-top: 0px;
    }
}

.brief_container {
    width: 80vw;
    font-size: 24px;
    text-align: justify;
    margin: 0 auto;
}

.devil {
    background: rgb(111, 0, 0);
    background: linear-gradient(180deg, rgba(111, 0, 0, 1) 0%, rgba(255, 57, 57, 1) 41%, rgba(255, 123, 35, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.angel {
    background: rgb(0, 136, 59);
    background: linear-gradient(180deg, rgba(0, 136, 59, 1) 0%, rgba(0, 255, 102, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}



.videoContainer {
    position: relative;
    width: 400px;
}

.demo_video {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.video_overlay {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    position: absolute;
    top: calc(50% - 4px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: calc(100% - 5px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.overlay_content .logo {
    width: 200px;
}

.playButton {
    color: #fff;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background: linear-gradient(30deg, rgb(255, 130, 0) 20%, rgb(255, 38, 0) 80%);
    transition: all 0.3s ease-in-out 0s;
    box-shadow: rgba(193, 244, 246, 0.698) 0px 0px 0px 0px;
    animation: 1.2s cubic-bezier(0.8, 0, 0, 1) 0s infinite normal none running pulse;
    align-items: center;
    border: 0;
}

.playButton:is(:hover, :focus) {
    animation-play-state: paused;
}

@keyframes pulse {
    100% {
        box-shadow: 0 0 0 45px rgba(193, 244, 246, 0);
    }
}

@media (max-width: 991px) {
    .mainSection {
        flex-direction: column;
        gap: 3rem;
    }

    .mainSection .mainSectionLeft {
        width: 80%;
    }

    .textSlideContainer div h1 {
        font-size: 56px;
    }

    .mainSection .textSlideContainer .secondText {
        top: -67px;
    }

    .videoContainer {
        width: 300px;
    }

    .playButton {
        width: 60px;
        height: 60px;
    }

    .overlay_content .logo {
        width: 150px;
    }

    .brief_container {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .textSlideContainer div h1 {
        font-size: 50px;
    }
    .mainSection .textSlideContainer .secondText {
        top: -60px;
    }
}
@media (max-width: 576px) {
    .textSlideContainer div h1 {
        font-size: 40px;
    }
    .mainSection .textSlideContainer .secondText {
        top: -48px;
    }
    .brief_container {
        font-size: 16px;
        width: 70vw;
    }   
}
@media (max-width: 466px) {
    .textSlideContainer div h1 {
        font-size: 40px;
    }
    .mainSection .textSlideContainer .secondText {
        top: -96px;
    }
}