.candidateCard{
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.candidateCardDetails{
    background-color: #fff;
    padding: 10px;
}
.candidateCard img{
    width: 85%;
    margin: 0 auto; 
}
.candidateCard .info{
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}
.candidateCard .designation{
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}
@media (max-width: 991px){
    .candidateCard .designation{
        font-size: 18px;
    }
}
@media (max-width: 991px){
    .candidateCard img{
        width: 55%;
    }
}