.valueCard {
    background: rgba(0, 0, 0, 0.7);
    border: 2px solid white;
    border-radius: 8px;
    padding: 20px 10px 10px 10px;
    font-size: 24px;
    color: white;
    text-align: center;

    flex: 1 1 calc(33.333% - 10px);
    /* Adjust the width of each item to one-third of the parent width, accounting for gaps */
    box-sizing: border-box;
    /* Ensures padding and border are included in the width and height */
    padding: 10px;
    /* Adds padding inside each item */
    margin: 5px 0;
    display: flex;
    flex-direction: column;
}

.valueCardImgContainer {
    height: 120px;
}

.valueCard img {
    height: 100px;
}

.valueCard h2 {
    margin-bottom: 2rem;
}

.valueCard .valueCardText {
    font-size: 24px;
}


@media (max-width: 991px) {
    .valueCard {
        flex: 1 1 calc(50% - 20px);
        box-sizing: border-box;
        max-width: calc(50% - 20px);
        order: 2;
    }

    .valueCard .valueCardText {
        font-size: 20px;
    }

    .valueCardImgContainer {
        height: 100px;
    }

    .valueCard img {
        height: 80px;
    }
}

@media (max-width: 768px) {
    .valueCardHeading {
        font-size: 24px;
    }
}

@media (max-width: 576px) {
    .valueCard {
        flex: 1 1 100%;
        box-sizing: border-box;
        max-width: 100%;
        order: 2;
    }

    .valueCard .valueCardText {
        font-size: 16px;
    }

    .valueCardImgContainer {
        height: 80px;
    }

    .valueCard img {
        height: 60px;
    }
    .valueCard h2 {
        font-size: 20px;
        margin-bottom: 1rem;
    }
}