.termsWrapper {
    background-color: #fff;
    color: #000;
    padding: 6rem 0;
}
.termsContainer{
    width: 70%;
    margin: 0 auto;
}
.termsContainer h1{
    font-size: 32px;
    margin: 3rem 0;
    font-weight: 600;
}
.termsContainer h2{
    font-size: 24px;
    font-weight: 600;
    margin-top: 3rem;
    margin-bottom: 2rem;
}
.termsContainer p{
    font-size: 20px;
}