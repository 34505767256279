.services_section {
    color: #fff;
    margin: 6rem auto;
    width: 80%;
    text-align: center;
}

.services_section>h1 {
    margin-top: 3rem;
    font-size: 64px;
}

.services_section .mainContainer {
    margin: 8rem 0;
}

.services_section .mainContainer h1 {
    font-size: 64px;
    margin-bottom: 2rem;
}

.services_section .mainContainer p {
    font-size: 24px;
}

.services_cards_container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 3rem 0;
    justify-content: center;
}

.services_cards_container .services_card {
    flex: 1 1 calc(33.333% - 2rem);
    max-width: calc(33.333% - 2rem);
    text-align: center;
    padding: 1rem;
    border: 2px solid #fff;
    border-radius: 10px;
    transition: all 0.5s;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.services_cards_container .services_card:hover {
    border: 1px solid #fff;
    transform: scale(1.03);
    box-shadow: 12px 17px 51px rgba(255, 255, 255, 0.22);
}

.services_cards_container .services_card h2 {
    margin: 2rem 0;
    color: var(--highlight-color);
}

.services_cards_container .services_card div p {
    text-align: justify;
    font-size: 20px;
}

.services_card img {
    height: 10rem;
}


@media (max-width: 1024px) {
    .services_cards_container .services_card {
        flex: 1 1 calc(50% - 2rem);
        max-width: calc(50% - 2rem);
    }
}

@media (max-width: 768px) {
    .services_section {
        margin: 4rem auto;
    }

    .services_section .mainContainer {
        margin: 6rem 0;
    }

    .services_cards_container .services_card {
        flex: 1 1 calc(100% - 2rem);
        max-width: calc(100% - 2rem);
    }

    .services_section .mainContainer h1 {
        font-size: 56px;
    }

    .services_card img {
        height: 8rem;
    }

    .services_section>h1 {
        margin-top: 2rem;
        font-size: 48px;
    }

    .services_section .mainContainer p {
        font-size: 20px;
        text-align: justify;
    }
}

@media (max-width: 576px) {
    .services_section .mainContainer {
        margin: 4rem 0;
    }
    .services_cards_container .services_card h2 {
        margin: 1rem 0;
        color: var(--highlight-color);
    }

    .services_section .mainContainer h1 {
        font-size: 48px;
        margin-bottom: 1rem;
    }
    .services_card img {
        height: 6rem;
    }

    .services_section>h1 {
        margin-top: 1rem;
        font-size: 36px;
    }
}