.file_upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 60px; */
}

.file_input {
    /* margin-bottom: 10px; */
    padding: 10px 16px !important;
}
.file_label {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin-bottom: 10px;
}

.upload_button {
    background-color: #007bff;
    color: white;
    border: none;
    /* border-radius: 5px; */
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.upload_button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.upload_button:focus {
    outline: none;
}

.file_name {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
}
.file_uploaded {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* height: 60px; */
    border: 1px solid #414141;
    padding: 0 10px;
    position: relative;
    grid-column-gap: 5px;
    border-radius: 8px;
}
.file_uploaded p{
    margin-bottom: 0;
    font-size: 16px;
    margin: 12px;
}
.crossIcon {
    display: flex;
    color: white;
    text-align: center;
    cursor: pointer;
    font-size: 24px!important;
}