.notfound_section{
    height: 90vh;
    color: white;
    display: flex;
    justify-content: space-around;
    padding: 3rem;

}

.notfound_section div img{
    width: 400px;
    height: 400px;
    background-color: white;
    padding: 2em;
    border-radius: 10px;
}
.notfound_section div{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.notfound_section h1{
    font-size: 72px;
    font-weight: 800;
}
.notfound_section .digit{
    font-size: 120px;
    color: var(--primary-color);
    letter-spacing: 5px;
}
.notfound_section p{
    font-size: 45px;
}
.notfound_section a{
    text-decoration: none;
    padding: 1rem 2rem;
    background-color: var(--primary-color);
    color: white;
    border-radius: 10px;
    border: 2px solid transparent;
}
.notfound_section a:hover{
    border: 2px solid white;
}