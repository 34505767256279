.team_section {
    color: #fff;
    padding-top: 4rem;
}

.team_section h1 {
    margin-bottom: 2rem;
}

.slider_container {
    width: 80%;
    margin: 0 auto;
}

.cardContainer {
    width: 20rem;
    padding: 10px;
    background-color: black;
    border-radius: 18px;
    position: relative;
}

.cardContainer:hover .cardImageContainer img {
    border: 1px solid #fff;
    transform: scale(1.01);
    box-shadow: 12px 17px 51px rgba(255, 255, 255, 0.22);
}

/* .cardDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    height: 100%;
    top: -150%;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(34, 34, 34, 0.8);
    backdrop-filter: blur(5px);
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
    border-radius: 18px;
    padding: 20px;
    transition: 0.5s;
} */


.cardImageContainer img {
    width: 100%;
    border-radius: 8px;
    height: 250px;
    object-fit: cover;
    object-position: top;
}

.cardDetailsContainer {
    padding: 10px 0px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cardDetailsContainer .teamMemberCardBtn,
.cardDetails .teamMemberCardBtn {
    text-decoration: underline;
    color: var(--primary-color);
    cursor: pointer;
}

.slick-slide {
    margin: 0 20px;
}

.slick-slide>div {
    margin: 0 20px;
}

.teamMemberName,
.teamMemberDetails {
    font-size: 18px;
}

.teamMemberDesignation {
    font-size: 24px;
}

@media (max-width: 1280px) {
    .teamMemberDesignation {
        font-size: 20px;
    }
    
    .teamMemberName,
    .teamMemberDetails {
        font-size: 16px;
    }
}
@media (max-width: 768px) {
    .slider_container {
        width: 70%;
    }    
}