.values_section {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 30px 50px;
    padding-top: 6rem;
}

.values_section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../assets/candidates.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(10px);
    z-index: -1;
}

.values_section::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
}

.valuesCardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.valuesHeadingContainer {
    flex: 1 1 calc(33.333% - 10px);
    box-sizing: border-box;
    padding: 10px;
    margin: 5px 0;
    text-align: center;
}

.valuesHeadingContainer h1 {
    font-size: 5rem;
}

@media (max-width: 991px) {
    .valuesCardContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    }
    
    .valuesHeadingContainer {
        order: 1;
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .valuesHeadingContainer h1 {
        font-size: 36px;
    }
}
@media (max-width: 576px) {
    .valuesHeadingContainer img{
        width: 80px;
    }
}