.lookingToHirePage_section {
    margin: 40px auto;
    color: white;
}


.hire_top_container .top_container_heading_box {
    width: 100%;
    background: rgb(255, 196, 67);
    background: linear-gradient(90deg, rgba(255, 196, 67, 1) 0%, rgba(255, 128, 0, 1) 100%);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem;
    margin-bottom: 3rem;

}

.hire_top_container .top_container_heading_box img {
    border-radius: 10px;
    width: 400px;
    height: 400px;
}


.hire_top_container .top_container_heading_box h1 {
    font-size: 64px;
    text-align: center;
    padding: 3rem;
    color: black;
    font-weight: 600;

}

.top_container_box {
    width: 80%;
    margin: 0 auto;
}

.top_container_box p {
    font-size: 24px;
    text-align: justify;
}

.hire_heading p {
    width: 80%;
    margin: 0 auto;
    font-size: 24px;
    text-align: justify;
    margin-bottom: 3rem;
}

.top_container_box .impText {
    color: var(--highlight-color);
    margin: 4rem 0;
    font-size: 32px;
    text-align: center;
    padding: 1rem 1.5rem;
    border: 2px solid var(--primary-color);
    border-top-left-radius: 65px;
    border-bottom-right-radius: 65px;
}

.formWrapper {
    width: 80%;
    margin: 0 auto;
}

.hire_heading h1 {
    text-align: center;
    margin-bottom: 4rem;
}

.formWrapper .btnContainer {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 991px) {
    .hire_top_container .top_container_heading_box h1 {
        font-size: 56px;
    }

    .hire_top_container .top_container_heading_box img {
        width: 300px;
        height: 300px;
    }
}

@media (max-width: 768px) {
    .hire_top_container .top_container_heading_box h1 {
        font-size: 48px;
        padding: 1rem;
    }

    .hire_top_container .top_container_heading_box img {
        width: 200px;
        height: 200px;
    }
}

@media (max-width: 576px) {
    .hire_top_container .top_container_heading_box {
        flex-direction: column-reverse;
    }

    .hire_top_container .top_container_heading_box h1 {
        font-size: 36px;
        padding: 1rem;
    }

    .hire_top_container .top_container_heading_box img {
        width: 200px;
        height: 200px;
    }

.hire_heading p, .top_container_box p {
        font-size: 20px;
    }

    .top_container_box .impText {
        font-size: 24px;
    }
}