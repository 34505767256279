.ourProcess_section{
    color: #fff;
}
.ourProcess_section > h1{
    width: 80%;
    margin: 0 auto;
    font-size: 72px;
    margin-top: 4rem;
}
@media (max-width: 991px){
    .ourProcess_section > h1{
        font-size: 56px;
        text-align: center;
    }
}
@media (max-width: 768px){
    .ourProcess_section > h1{
        font-size: 48px;
    }
    
}
@media (max-width: 576px){
    .ourProcess_section > h1{
        font-size: 36px;
    }
    
}