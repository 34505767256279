.findJobs_section {
    text-align: center;
    margin: 40px auto;
    color: white;
}

.main_container .main_container_box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    background-color: var(--primary-color);
    padding: 2rem;
    /* background: rgb(255, 196, 67);
    background: linear-gradient(90deg, rgba(255, 196, 67, 1) 0%, rgba(255, 128, 0, 1) 100%); */
}

.main_container_box .main_container_box_image img {
    width: 400px;
    height: 400px;
    border-radius: 10px;
}

.main_container .main_container_heading {
    padding: 3rem;
    color: #000;
}

.main_container .main_container_context {
    width: 80%;
    margin: 3rem auto;
}

.main_container .main_container_context :first-child {
    text-align: justify;
}

.findJobs_section h1 {
    font-weight: 800;
    font-size: 70px;
}

.findJobs_section p {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 3rem;
}

.main_container_context .impText {
    color: var(--highlight-color);
    margin: 4rem 0;
    font-size: 32px;
    text-align: center;
    padding: 1rem 1.5rem;
    /* background-color: #3d3d3d; */
    border: 2px solid var(--primary-color);
    border-top-left-radius: 65px;
    border-bottom-right-radius: 65px;
}



.findJobs_section button:hover {
    color: #000;
    transform: translate(-0.25rem, -0.25rem);
    background: #ABD7E3;
    font-weight: 600;
    box-shadow: 0.25rem 0.25rem 10px #000;
}

.findJobs_section button:active {
    transform: translate(0);
    box-shadow: none;
}


.formWrapper {
    width: 80%;
    margin: 0 auto;
}

.formWrapper .btnContainer {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 991px) {
    .main_container_box .main_container_box_image img {
        width: 300px;
        height: 300px;
    }

    .findJobs_section h1 {
        font-size: 56px;
    }
}

@media (max-width: 768px) {
    .main_container_box .main_container_box_image img {
        width: 200px;
        height: 200px;
    }

    .findJobs_section h1 {
        font-size: 36px;
    }
}

@media (max-width: 576px) {
    .main_container .main_container_box {
        flex-direction: column;
    }

    .main_container .main_container_heading {
        padding: 1rem;
    }
    .findJobs_section p {
        font-size: 20px;
        margin-bottom: 2rem;
    }
.main_container_context .impText {
    font-size: 20px;
}
}