.candidates_section {
    width: 80vw;
    margin: 30px auto;
    padding-top: 4rem;
    color: #fff;
    text-align: center;

}

.candidates_section p {
    font-size: 24px;
}

.candidates_card_container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

@media (max-width: 991px) {
    .candidates_card_container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .candidates_card_container {
        grid-template-columns: repeat(2, 1fr);
        width: 80%;
        margin: 2rem auto;
    }

    .candidates_section p {
        font-size: 20px;
    }
}

@media (max-width: 576px) {
    .candidates_card_container {
        grid-template-columns: repeat(1, 1fr);
    }
}