.process_info {
    text-align: center;
    width: 80%;
    margin: 30px auto;
}

.process_info h1 {
    font-size: 72px;
    margin-bottom: 3rem;
}

.process_info p {
    font-size: 24px;
}

.process_steps_container {
    width: 90%;
    margin: 0 auto;
    margin-top: 8rem;
    display: flex;
    flex-direction: column;
}

.process_step {
    margin: 6rem 0px;
    width: 80%;
    padding: 0rem 5rem;
}

.process_step:nth-child(odd) {
    align-self: flex-start;
    border-left: 2px solid rgba(255, 255, 255, 0.7);
}

.process_step:nth-child(even) {
    align-self: flex-end;
    border-right: 2px solid rgba(255, 255, 255, 0.7);

}


.process_steps_container .step_num {
    color: var(--primary-color);
}

.process_steps_container .step_heading {
    font-size: 64px;
    color: var(--highlight-color);
}

.process_steps_container .step_detail {
    font-size: 24px;
}

.process_steps_container .step_list {
    list-style: none;
    font-size: 24px;
}

.process_steps_container .step_list .listIcon {
    color: var(--primary-color);
    font-size: 24px;
    margin-right: 20px;
}

@media (max-width: 991px) {
    .process_info h1 {
        font-size: 56px;
        margin-bottom: 3rem;
    }

    .process_steps_container .step_heading {
        font-size: 56px;
    }
}

@media (max-width: 768px) {
    .process_info h1 {
        font-size: 48px;
    }

    .process_steps_container .step_heading {
        font-size: 48px;
    }

    .process_info p {
        text-align: justify;
    }

    .process_step {
        margin: 3rem 0px;
        width: 90%;
        padding: 0rem 2rem;
    }

}

@media (max-width: 576px) {
    .process_info h1 {
        font-size: 36px;
    }
    .process_info p {
        font-size: 20px;
    }
    .process_steps_container .step_list {
        font-size: 20px;
    }

    .process_steps_container .step_heading {
        font-size: 36px;
    }

    .process_step {
        margin: 2rem 0px;
        width: 100%;
        padding: 0rem 1rem;
    }
    .process_steps_container .step_detail {
        font-size: 20px;
    }

}