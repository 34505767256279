.clients-section {
    padding-top: 4rem;
    margin-bottom: 2rem;
}


@keyframes slide {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.logos-container {
    background-color: white;
}

.logos {
    overflow: hidden;
    background-color: white;
    white-space: nowrap;
    position: relative;
}

.logos::before,
.logos::after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
}

.logos::before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos::after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos-slide {
    display: inline-block;
    animation: slide 45s infinite linear;
}

.logos-slide img {
    height: 140px;
    margin: 0 30px;
}

@media (max-width: 768px){
    .clients-section{
        margin-top: 2rem;
    }
    .logos-slide img {
        height: 120px;
        margin: 0 30px;
    }
    .logos::before {
        left: 0;
        background: transparent;
    }
    
    .logos::after {
        right: 0;
        background: transparent;
    }
}

@media (max-width: 576px){
    .clients-section {
        padding-top: 2rem;
    }
    .logos-slide img {
        height: 80px;
    }
}