.leaderShip_section {
    padding-top: 4rem;
}

.leaderShip_container>h1 {
    text-align: center;
}

.leaderShip_container .leader_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4rem;
    color: white;
    margin: 4rem 0;
    margin-bottom: 8rem;
}

.leader_box img {
    width: 300px;
}

.leader_box .name {
    font-weight: 700;
    margin-right: 20px;
    font-size: 56px;
}

.leader_box .designation {
    font-size: 32px;
}

.leader_box p {
    font-size: 20px;
}

@media (max-width: 991px) {
    .leader_box .name {
        font-weight: 600;
        font-size: 48px;
    }

    .leader_box .designation {
        font-size: 24px;
    }

    .leader_box img {
        width: 200px;
    }
}

@media (max-width: 768px) {
    .leaderShip_container .leader_box {
        flex-direction: column;
        gap: 2rem;
        margin: 2rem 0;
    }
    .leaderShip_container .leader_box h1, .leaderShip_container .leader_box h2{
        text-align: center;
    }
    .leaderShip_container .leader_box p{
        text-align: justify;
        margin-top: 20px;
    }
    .leaderShip_container .leader_box_second{
        flex-direction: column-reverse;
        margin-top: 4rem;
    }
}