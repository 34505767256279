.floating_button,
.floating_btn_jobs {
    height: 50px;
    width: 170px;
    border: none;
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid white;
    color: white;
    position: fixed;
    right: -60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 0.5s ease;
    overflow: hidden;
    z-index: 9;
    transform: rotate(-90deg);
    font-size: 20px;
}

.floating_button {
    top: 30%;
}

.floating_btn_jobs {
    top: calc(30% + 180px);
}

.floating_button:hover,
.floating_btn_jobs:hover {
    transform: scale(1.1);
    transform: rotate(-90deg);
    /* background-color: rgba(0, 0, 0, 0.9); */
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
}

.floating_button:hover,
.floating_btn_jobs:hover {
    background-position: right;
}

.floating_button:hover::before,
.floating_btn_jobs:hover::before {
    background-position: right;
}

.floating_button:active,
.floating_btn_jobs:active {
    transform: scale(0.95);
    transform: rotate(-90deg);
}

@media (max-width: 576px) {

    .floating_button,
    .floating_btn_jobs {
        height: 40px;
        width: 160px;
        right: -60px;
    }
    .floating_btn_jobs {
        top: calc(30% + 180px);
    }
}