.culture_section {
    margin: 0 auto;
    color: white;

}

.culture_section .culture_main_container {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    height: 90vh;
    text-align: center;
    position: relative;
}

.culture_section .culture_main_container img {
    width: 300px;
}

.culture_section .culture_main_container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../assets/Culture/Culture6.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
}

.culture_section .culture_main_container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: -1;
}

.culture_section .culture_main_container h1 {
    font-size: 72px;
    font-weight: 700;
    text-align: center;
    margin: 0 2rem;
}

.culture_section .highlight_text {
    color: var(--primary-color);
}


.celebration_container {
    margin: 0px auto;
    width: 100%;
    padding: 3rem;
}

.celebration_container .celeb_box {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    align-items: center;
    margin: 4rem 0;
}

.celebration_container .celeb_box img {
    height: 250px;
}

.celebration_container .celeb_box h1 {
    font-size: 100px;
    font-weight: 800;
}

.collage {
    width: 90%;
    margin: 0 auto;
}

.collage h1 {
    text-align: center;
}


.collage .gridContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    padding: 20px;
}

.collage .gridContainer .gridRow_first,
.collage .gridContainer .gridRow_third {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    height: 500px;
}

.collage .gridContainer .gridRow_second {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    height: 400px;
}

.collage .gridContainer .gridItem {
    overflow: hidden;
    text-align: center;
    border: 2px solid transparent;
}

.collage .gridContainer .gridItem img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: 0.5s all ease;
}

.collage .gridContainer .gridItem img:hover {
    border: 2px solid var(--primary-color);
    scale: 1.2;
}

.collage .gridContainer .gridItem:hover {
    border: 2px solid var(--primary-color);
}

@media (max-width: 991px) {
    .celebration_container .celeb_box img {
        height: 250px;
        width: 250px;
    }

    .celebration_container .celeb_box h1 {
        font-size: 72px;
    }

}

@media (max-width: 768px) {
    .culture_section .culture_main_container h1 {
        font-size: 56px;
    }

    .celebration_container {
        padding: 3rem;
    }

    .celebration_container .celeb_box {
        margin: 2rem auto;
    }
}

@media (max-width: 576px) {
    .culture_section .culture_main_container h1 {
        font-size: 48px;
    }

    .culture_section .culture_main_container {
        height: 60vh;
    }

    .culture_section .culture_main_container::before {
        height: 60vh;
    }

    .culture_section .culture_main_container::after {
        height: 60vh;
    }

    .celebration_container .celeb_box h1 {
        font-size: 36px;
    }

    .celebration_container .celeb_box img {
        height: 120px;
        width: 120px;
    }

    .collage .gridContainer {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(1 1fr);
        gap: 20px;
    }

    .collage .gridContainer .gridRow_first,
    .collage .gridContainer .gridRow_third {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 20px;
        height: 500px;
        order: 1;
    }
    .collage .gridContainer .gridRow_first .fullWidth,
    .collage .gridContainer .gridRow_third .fullWidth {
        grid-column: span 2;
      }

    .collage .gridContainer .gridRow_second {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        height: 400px;
        order: 2;
    }


}