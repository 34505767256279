.testimonials_section {
    margin: 30px auto;
    padding-top: 4rem;
    width: 80vw;
}

.testimonials_section>h1 {
    margin-bottom: 2rem;
}

.testimonialsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.testimonialCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* .testimonialsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
} */

.testimonialText {
    padding: 10px;
    background-color: #fff;
    color: #000;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    height: 100%;
}

.testimonialText img {
    width: 50px;
}

.testimonialText p {
    margin: 10px;
    font-weight: 600;
}

.testimonialInfo {
    background-color: #110000;
    color: #fff;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    display: flex;
    gap: 5px;
    padding: 10px 20px;
}

.testimonialInfo img {
    width: 80px;
    border-radius: 50px;
}

.testimonialInfo div p {
    margin: 0;
}

@media (max-width: 991px) {
    .testimonialsContainer {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }

    /* .card_center {
        grid-column: span 2;
        justify-self: center;
        width: 50%;
    } */
}

@media (max-width: 576px) {
    .testimonials_section {
        width: 70vw;
    }
    .testimonialsContainer {
        grid-template-columns: repeat(1, 1fr);
    }

    .card_center {
        grid-column: unset;
        justify-self: center;
        width: unset;
    }

    .testimonialInfo img {
        width: 60px;
    }

}