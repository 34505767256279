.myheader {
    box-shadow: 0 4px 13px -3px rgba(0, 0, 0, 0.5);
    height: 5rem;
    background-color: #686868;
    padding: 0 2rem !important;
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
}

.myheader nav {
    width: 100%;
    height: 5rem;
    padding: 10px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.navLogoLink {
    z-index: 999;
}

.navbar_logo {
    height: 3rem;
}

.myheader nav ul {
    float: right;
    margin-right: 20px;
    list-style-type: none;
    margin: 0;
}

.myheader nav ul li {
    display: inline;
    margin: 0px 10px;
}

.myheader .navLink {
    transition: color 0.3s;
    margin: 0px 5px;
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    padding: 7px 10px;
}

.myheader .navLink:hover {
    color: #ffcf77 !important;
    background: black;
    border-radius: 25px;
}

.header_active {
    color: #ffcf77 !important;
    background: black;
    border-radius: 25px;
}

/* Animated Hamburger Menu Icon */
/* Begin */
.myheader nav .menu {
    position: absolute;
    /* top: 8px; */
    right: 40px;
    /* background: rgba(0, 0, 0, 0.2); */
    width: 42px;
    height: 42px;
    margin: 0px 20px;
    cursor: pointer;
    display: none;
    z-index: 999;
}

.myheader nav .menu span {
    position: absolute;
    top: 10px;
    width: 30px;
    margin: 0px 5px;
    height: 3px;
    background: #fff;
    z-index: 1;
    transition: 0.5s;
}

.myheader nav .menu span:nth-child(2) {
    top: 20px;
}

.myheader nav .menu span:nth-child(3) {
    top: 30px;
}

.myheader nav .menu.active span:nth-child(1) {
    transform: translateY(10px) translateX(0px) rotate(-45deg) scale(1.12);
}

.myheader nav .menu.active span:nth-child(2) {
    opacity: 0;
}

.myheader nav .menu.active span:nth-child(3) {
    transform: translateY(-10px) translateX(0px) rotate(45deg) scale(1.12);
}

/* End */
@media (max-width: 1200px) {
    .myheader nav ul li {
        margin: 0px;
    }

    .myheader .navLink {
        margin: 0px 5px;
        font-size: 16px;
        padding: 4px 8px;
    }
}

@media(max-width:991px) {
    .myheader nav {
        width: 100%;
        height: 5rem;
        padding: 10px 0;
        display: flex;
        justify-content: start;
        align-items: center;
    }

    .myheader nav ul {
        position: absolute;
        top: 5rem;
        left: -1000px;
        text-align: center;
        width: 30%;
        height: 100vh;
        background: pink;
        transition: 0.5s;
        padding-left: 0px;
    }

    .myheader .menubar.active {
        position: absolute;
        left: 0px;
    }

    .myheader nav .menu {
        display: block;
    }

    .myheader nav ul li {
        display: block;
    }

    .myheader nav ul li a {
        display: block;
        margin: 2px 0px;
    }

    .myheader .menubar li {
        padding: 5px 5px;
        margin: 2px 0;
    }

    .myheader .menubar.active {
        color: #ffcf77 !important;
        background: black;
        z-index: 999;
    }

    .myheader .container nav .toggle {
        display: block;
        background-color: yellow;
    }
}

@media(max-width:768px) {
    .myheader nav ul {
        width: 40%;
    }
}

@media(max-width:468px) {
    .myheader nav ul {
        width: 50%;
    }
}