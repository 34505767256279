.skills-section {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 20px 0px;
    padding-top: 5rem;
}

.skills-section h1 {
    width: 80%;
    margin: 0 auto;
    font-size: 3rem;
}

@keyframes skillsSlide {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

@keyframes skillsSlideReverse {
    from {
        transform: translateX(-70%);
    }

    to {
        transform: translateX(0);
    }
}


.skills {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

.skills::before,
.skills::after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
}

.skills-slide {
    display: inline-block;
    animation: skillsSlide 60s infinite linear;
    padding: 3rem;
}

.skills-slide-reverse {
    animation: skillsSlideReverse 60s infinite linear;
}

.skills-slide span {
    padding: 1.5rem 1rem;
    font-size: 20px;
    background-color: grey;
    color: #fff;
    border-radius: 10px;
    margin: 0 5px;
}

.skills-slide span:hover {
    background-color: var(--primary-color);
    color: #000;
    cursor: default;
}

@media (max-width: 991px) {
    .skills-section h1 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    .skills-slide {
        padding: 2rem;
    }

    .skills-slide span {
        font-size: 16px;
        padding: 1rem 0.5rem;
    }
}

@media (max-width: 768px) {
    .skills-section {
        margin-top: 2rem;
    }

    .skills-slide {
        padding: 1rem;
    }

}