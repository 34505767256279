.applications_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 5rem;
}

.applications_container .applications_container_child {
    flex: 1;
    width: 50%;
    box-sizing: border-box;
    padding: 0px 30px;
}

.applications_text_side>p:first-child {
    color: var(--primary-color);
    text-transform: uppercase;
    font-size: 24px;
}

.applications_text_side>h1 {
    font-size: 56px;
    color: var(--highlight-color);

}

.applications_details {
    font-size: 20px;
}

.applications_img_side img {
    width: 100%;
}

@media (max-width: 991px) {
    .applications_container {
        padding: 3rem;
        flex-direction: column-reverse;
        gap: 3rem;
    }

    .applications_container_second {
        flex-direction: column;
    }

    .applications_container .applications_container_child {
        flex: 1;
        width: 100%;
    }

    .applications_text_side>h1 {
        font-size: 48px;
    }

    .applications_details {
        text-align: justify;
    }
}

@media (max-width: 768px) {
    .applications_text_side>h1 {
        font-size: 36px;
    }

}

@media (max-width: 576px) {
    .applications_text_side>h1 {
        font-size: 24px;
    }
    .applications_container {
        padding: 1rem;
    }

}