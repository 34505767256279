.privacyWrapper {
    background-color: #fff;
    color: #000;
    padding: 6rem 0;
}

.privacyPolicyContainer h1 {
    font-size: 32px;
    margin: 3rem 0;
    font-weight: 600;
}

.privacyPolicyContainer {
    width: 70%;
    margin: 0 auto;
}

.privacyPolicyContainer h3 {
    font-weight: 600;
}

.privacyPolicyContainer p {
    font-size: 20px;
}

.privacyPolicyContainer .policyDetail {
    margin: 2rem 0;
}

.privacyPolicyContainer h2 {
    font-size: 32px;
    font-weight: 600;
}