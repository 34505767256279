.assistant {
  font-family: "Assistant", sans-serif !important;
}

.section-100vh {
  height: 100vh;
}

.section-30vh {
  height: 30vh;
}


.width-100 {
  width: 100%;
}

.orange {
  background-color: orange;
}

:root {
  --bg-color: #212529;
  --primary-color: #ff9900;
  --highlight-color: #00ecff;

  --heading-size: 56px;


  --semibold: 600;
  --bold: 700;
  --extrabold: 800;
}

.primary-btn {
  background-color: var(--primary-color) !important;
  color: black !important;
  border-radius: 25px;
}

.outline-btn {
  background-color: transparent;
  border: 2px solid black;
  color: black;
}

.contact-btn {
  background-color: var(--primary-color) !important;
  color: black !important;
  border-radius: 25px;
}

.bold-text {
  font-weight: var(--semibold);
}

.mainHeading {
  /* font-weight: var(--extrabold);
  font-size: var(--heading-size);
  color: #fff; */
  font-size: 6em;
  font-weight: bold;

  background: rgb(2, 11, 93);
  background: linear-gradient(90deg, var(--highlight-color) 0%, rgba(219, 219, 219, 1) 44%, rgba(255, 207, 119, 1) 70%, rgba(240, 64, 10, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/* animation */
.fade-in {
  opacity: 0;
  animation: fadeIn 2s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}


.text-24 {
  font-size: 24px;
}

/* Styling Form */
.form-container {
  width: 400px;
  background: linear-gradient(#212121, #212121) padding-box,
    linear-gradient(145deg, transparent 35%, var(--primary-color), #fa6b32) border-box;
  border: 5px solid transparent;
  padding: 32px 24px;
  font-size: 14px;
  font-family: inherit;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  background-size: 200% 100%;
  animation: gradient 5s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.form-container button:active {
  scale: 0.95;
}

.form-container .form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
  text-align: left;
  /* max-width: 800px; */
}

.form-container .form-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 10px;
}

.form-container .form-group label {
  display: block;
  margin-bottom: 5px;
  color: #fff;
  /* font-weight: 600; */
  font-size: 16px;
}

.form-container .form-group input,
.form-container .form-group select {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  color: #fff;
  font-family: inherit;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #414141;
}

/* .form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23ccc" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>'); 
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
} */
.form-group option {
  padding: 20px 0px;
  background-color: darkgrey;
  color: #fff;
}

.form-group option:hover {
  background-color: #ff2f2f;
}

.form-container .form-group textarea {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  resize: none;
  color: #fff;
  height: 96px;
  border: 1px solid #414141;
  background-color: transparent;
  font-family: inherit;
  font-size: 16px;
}


.form-container .form-group input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.form-container .form-group textarea:focus {
  outline: none;
  border-color: var(--primary-color);
}

.form_error {
  color: var(--primary-color);
  font-size: 14px;
  margin-top: 5px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* .form-container .form-submit-btn {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: flex-end;
  font-family: inherit;
  color: #fff;
  width: 30%;
  background: #313131;
  border: 1px solid #414141;
  padding: 12px 16px;
  font-size: 16px;
  gap: 8px;
  margin-top: 8px;
  cursor: pointer;
  border-radius: 25px;
  transition: background-color 0.5s ease;
}

.form-container .form-submit-btn:hover {
  background-color: #fff;
  border-color: #000;
  color: black;
  font-weight: 600;
} */

/* submit button styling */
.submit-btn {
  font-family: inherit;
  display: inline-block;
  width: 8em;
  height: 3rem;
  line-height: 2.5em;
  margin: 20px;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--primary-color);
  background-color: #000;
  transition: color .5s;
  z-index: 1;
  font-size: 17px;
  border-radius: 25px;
  font-weight: 600;
  color: var(--primary-color);
}

.submit-btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--primary-color);
  height: 150px;
  width: 200px;
  border-radius: 50%;
}

.submit-btn:hover {
  color: #fff;
}

.submit-btn:before {
  top: 100%;
  left: 100%;
  transition: all .7s;
}

.submit-btn:hover:before {
  top: -30px;
  left: -30px;
}

.submit-btn:active:before {
  background: var(--primary-color);
  transition: background-color 0s;
}
.special_btn{
  border: 2px solid var(--primary-color);
  border-radius: 30px;
  background-color: var(--primary-color);
}
.special_btn_outline{
  border: 2px solid var(--primary-color);
  border-radius: 30px;
}


@media (max-width: 991px){
  .mainHeading{
    font-size: 56px;
  }
}

@media (max-width: 768px) {
  .form-container .form {
    grid-template-columns: 1fr;
  }

  .form .btnContainer {
    justify-content: center;
  }
  .mainHeading {
    font-size: 36px;
  }

  .text-24 {
    font-size: 20px;
  }
}


.slick-slide>div {
  margin: 0 10px;
}