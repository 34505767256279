.aboutUs_section {
    color: #fff;
    width: 100%;
    margin: 0 auto;
}

.knowAboutContainer {
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: relative;
    padding: 8rem 5rem;
    background-color: rgba(0, 0, 0, 0.5);
}

.knowAboutContainer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../assets/group.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(1px);
    /* filter: blur(10px); */
    z-index: -1;
}

.knowAboutContainer::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: -1;
}

.knowAboutContainer h1 {
    font-size: 90px;
    margin-bottom: 2rem;
    font-weight: 800;
}

.knowAboutContainer>p {
    font-size: 24px;
    text-align: justify;
    width: 80%;
}

.secondContainer {
    /* background-color: #D8D8D8; */
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 80%;
    margin: 5rem auto;
}

.visionContainer,
.missionContainer {
    display: flex;
    gap: 3rem;
    margin: 3rem 0;
}

.visionContainer h1,
.missionContainer h1 {
    font-weight: 600;
    margin-bottom: 2rem;
    font-size: 64px;
    display: inline-block;
}

.visionContainer p,
.missionContainer p {
    font-size: 20px;
}

.visionContainer img,
.missionContainer img {
    height: 300px;
    width: 400px;
    object-fit: cover;
    /* Ensures the image covers the area without distortion */
    object-position: center;
}

.leadership_container {
    width: 80%;
    margin: 0 auto;
}

.thirdContainer {
    text-align: center;
    margin: 6rem 0;
}

.companyValues {
    display: flex;
    gap: 2rem;
    padding: 3rem;
}

.companyValues .valuesCard {
    text-align: center;
    padding: 1rem;
    border: 2px solid #fff;
    border-radius: 10px;
    transition: all 0.5s;
}

.companyValues .valuesCard:hover {
    border: 1px solid #fff;
    transform: scale(1.03);
    box-shadow: 12px 17px 51px rgba(255, 255, 255, 0.22);
}

.companyValues .valuesCard h2 {
    margin: 2rem 0;
    color: var(--highlight-color);

}

.companyValues .valuesCard p {
    text-align: justify;
    font-size: 20px;
}

.valuesCard img {
    height: 10rem;
}

.whyUsContainer {
    width: 80%;
    margin: 2rem auto;
}

.whyUsContainer h1 {
    font-size: 64px;
    margin-bottom: 2rem;
    display: inline-block;
}

.whyUsContainer p {
    font-size: 24px;
}

.whyUsContainer .pointsContainer {
    display: flex;
    gap: 20px;
    width: 100%;
    transition: all 0.5s ease-in-out;
}

.points {
    width: 100%;
}

.point {
    border: 2px solid white;
    background-color: transparent;
    color: white;
    margin: 10px;
    padding: 1rem;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.activePoint {
    background-color: #686868;
    border: 2px solid var(--primary-color);
    transition: all 0.5s ease;
}

.activePoint h2 {
    color: var(--primary-color);
}

.pointDetail {
    width: 100%;
    background: rgb(31, 30, 30);
    margin: 10px;
    padding: 20px;
}

.pointDetail h1 {
    color: var(--primary-color);
}

.link {
    color: var(--primary-color);
    text-decoration: underline;
}

@media (max-width: 991px) {
    .knowAboutContainer h1 {
        font-size: 56px;
    }

    .knowAboutContainer p {
        font-size: 20px;
    }

    .secondContainer {
        margin: 2rem auto;
    }

    .visionContainer,
    .missionContainer {
        align-items: center;
        margin: 1rem auto;
    }

    .visionContainer h1,
    .missionContainer h1 {
        font-size: 56px;
    }

    .visionContainer img,
    .missionContainer img {
        height: 200px;
        width: 250px;
    }

    .thirdContainer {
        text-align: center;
        margin: 6rem 0;
    }

    .valuesCard img {
        height: 6rem;
    }

    .companyValues .valuesCard h2 {
        margin: 1rem 0;
        font-size: 24px;

    }

    .pointDetail h1 {
        font-size: 56px;
    }

    .whyUsContainer p {
        font-size: 24px;
    }
    .companyValues {
        flex-wrap: wrap;
    }
}

@media (max-width: 768px) {
    .knowAboutContainer h1 {
        font-size: 56px;
    }
}

@media (max-width: 576px) {
    .knowAboutContainer h1 {
        font-size: 48px;
    }

    .knowAboutContainer>p {
        width: 90%;
    }

    .knowAboutContainer {
        padding: 4rem 2rem;
    }

    .secondContainer {
        margin: 3rem auto;
    }

    .visionContainer {
        flex-direction: column-reverse;
        text-align: center;
    }

    .missionContainer {
        flex-direction: column;
        text-align: center;
    }

    .visionContainer h1,
    .missionContainer h1 {
        font-size: 48px;
    }

    .visionContainer p,
    .missionContainer p {
        text-align: justify;
    }

    .visionContainer img,
    .missionContainer img {
        height: 150px;
        width: 200px;
    }
    /* .whyUsContainer .pointsContainer {
        flex-direction: column-reverse;
    }
    .whyUsContainer .pointsContainer .points{
        display: flex;
        flex-wrap: wrap;
    } */
    .whyUsContainer .pointsContainer .points{
        display: none;
    }
}